import { createContext } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type ContextType = {
  toast: (message: string) => void;
  //   show: (
  //     head: JSX.Element,
  //     body: JSX.Element,
  //     actions: JSX.Element
  //   ) => void;
} | null;

const ToastContext = createContext<ContextType>(null);

export default ToastContext;

const TOAST_OPTIONS = {};

export function WithToaster({ children }: { children: JSX.Element }) {
  // const [isOpen, setIsOpen] = useState<boolean>(false);
  // const [head, setHead] = useState<JSX.Element>(<></>);
  // const [body, setBody] = useState<JSX.Element>(<></>);
  // const [actions, setActions] = useState<JSX.Element>(<></>);

  // const onClose = () => setIsOpen(false)
  // const show = (
  //   head: JSX.Element,
  //   body: JSX.Element,
  //   actions: JSX.Element
  // ) => {
  //   setHead(head);
  //   setBody(body);
  //   setActions(actions);

  //   setIsOpen(true);
  // };

  const toastPop = (message: string) => {
    toast(message, TOAST_OPTIONS);
  };
  return (
    <ToastContext.Provider value={{ toast: toastPop }}>
      <ToastContainer />
      {children}
    </ToastContext.Provider>
  );
}
