import Modal from "@/components/Modal";
import { createContext, useState } from "react";

type ContextType = {
  wide: () => void;
  open: (head: JSX.Element, body: JSX.Element, actions: JSX.Element) => void;
  close: () => void;
} | null;

const GlobalModalContext = createContext<ContextType>(null);

export default GlobalModalContext;

export function WithGlobalModal({ children }: { children: JSX.Element }) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [head, setHead] = useState<JSX.Element>(<></>);
  const [body, setBody] = useState<JSX.Element>(<></>);
  const [actions, setActions] = useState<JSX.Element>(<></>);

  const [isWide, setWide] = useState<boolean>(false);

  const onClose = () => setIsOpen(false);

  const wide = (isWide = true) => setWide(isWide);
  const open = (
    head: JSX.Element,
    body: JSX.Element,
    actions?: JSX.Element,
  ) => {
    head && setHead(head);
    body && setBody(body);
    actions && setActions(actions);

    setIsOpen(true);
  };
  const close = () => onClose();
  return (
    <GlobalModalContext.Provider value={{ open, wide, close }}>
      <Modal
        id="global_modal"
        head={head}
        body={body}
        actions={actions}
        isOpen={isOpen}
        onClose={onClose}
        wide={isWide}
      />
      {children}
    </GlobalModalContext.Provider>
  );
}
