import { WithGlobalModal } from "@/contexts/WithGlobalModal";
import { WithToaster } from "@/contexts/WithToaster";
import "@/styles/globals.css";
import type { AppProps } from "next/app";
// import { SessionProvider } from "next-auth/react";
import Error from "next/error";
import { ErrorBoundary } from "react-error-boundary";
// import { AuthProvider } from "@/lib/Auth";

export default function App({ Component, pageProps }: AppProps) {
  if (pageProps.error) {
    console.error(pageProps.error);
    return (
      <Error
        statusCode={pageProps.error.statusCode}
        title={pageProps.error.message}
      />
    );
  }
  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <WithGlobalModal>
        <WithToaster>
          <Component {...pageProps} />
        </WithToaster>
      </WithGlobalModal>
    </ErrorBoundary>
  );
}
