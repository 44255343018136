import clsx from "clsx";
import { useRef } from "react";

interface ModalType {
  id: string;
  head?: JSX.Element | string;
  body: JSX.Element;
  actions?: JSX.Element;
  isOpen: boolean;
  onClose?: () => void;
  wide?: boolean;
}

export default function Modal({
  id,
  head,
  body,
  actions,
  isOpen,
  onClose,
  wide,
}: ModalType) {
  const dialog = useRef(null);

  return (
    <dialog
      id={id}
      className={clsx("modal modal-bottom sm:modal-middle", {
        "modal-open": isOpen,
      })}
      ref={dialog}
    >
      <form
        method="dialog"
        className={clsx("modal-box", { "!max-w-full": wide })}
        onSubmit={() => onClose && onClose()}
      >
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
          ✕
        </button>
        {head && <h3 className="font-bold text-lg">{head}</h3>}
        <div className="py-4"> {body}</div>
        {actions && <div className="modal-action">{actions}</div>}
      </form>
    </dialog>
  );
}
